import axios from "axios";
export const TRASH_API_HOST_URL = process.env.REACT_APP_TRASH_API_URL


export default async function Trash(accessToken, selectedFile) {
    let returnResponse = {
        data: [],
        result: false,
        status: ""
    };
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    try {
        const response = await axios.get(
            `${TRASH_API_HOST_URL}`,
            { headers: headers }
        );

        if (response.status === 200) {
            returnResponse.data = response.data;
            returnResponse.status = response.status;
            returnResponse.result = true;
        } else {
            console.error('Request failed with status:', response.status);
            returnResponse.status = response.status;
        }
    } catch (error) {
        console.error('Request error:', error);
        returnResponse.status = error;
    }
    return returnResponse;
}