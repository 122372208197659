import { useEffect, useState } from "react";
import FeedbackService from "../services/feedbackService";
import "../newComponent/Feedback.css";

export default function Feedback({ accessToken, uploadedFiles, triggerFeedbackSubmit, handleFeedbackSubmitComplete }) {
  const [txtFeedback, setTxtFeedback] = useState("");
  let _feedbackResult = {
    data: [],
    result: false,
    status: "",
    errorType: "Validation" | "Server" | "Unknown"
  };

  useEffect(() => {
    if (triggerFeedbackSubmit) {
      console.log("Feedback Result:", "trigerred");
      handleFeedbackSubmit();
    }
  }, [triggerFeedbackSubmit]);

  const validateFeedbackBody = () => {
    if (txtFeedback.trim().length <10 || txtFeedback.trim().length > 500) {
      return false;
    } else {
      return true;
    }
  };

  const handleFeedbackSubmit = async (e) => {
    if (validateFeedbackBody() === false) {
      _feedbackResult.errorType = "Validation";
      _feedbackResult.status = "Please provide feedback.\nFeedback should be between 10 and 500 characters.";
      handleFeedbackSubmitComplete(_feedbackResult);
      return false;
    }

    try {
      _feedbackResult = await FeedbackService(accessToken, txtFeedback, uploadedFiles);
      handleFeedbackSubmitComplete(_feedbackResult);
    } catch (err) {
      console.log("Error sending Feedback:", err);
      _feedbackResult.status = err;
      _feedbackResult.result = false;
      _feedbackResult.data = [];
      handleFeedbackSubmitComplete(_feedbackResult);
    }
  };

  return (
    <textarea
      placeholder="Feedback"
      className="body-input"
      value={txtFeedback}
      maxLength={250}
      rows={3}
      onChange={(e) => setTxtFeedback(e.target.value)}
      onBlur={validateFeedbackBody}
      required
    />
  );
}