import "../newComponent/EmptyFolderImage.css";

import emptyFileImage from "../assets/new.png";

const EmptyFolderImage = ({ dragActive }) => {
  return (
    <div className="container">
      <div className={`hover-container ${dragActive ? "dragActive" : ""}`}>
        <span className={`image-container ${dragActive ? "dragActive" : ""}`}>
          <img
            src={emptyFileImage}
            alt="actual-image"
            className={`my-image ${dragActive ? "dragActive" : ""}`}
          />
        </span>
        <span className={`heading-container ${dragActive ? "dragActive" : ""}`}>
          <p className={`heading ${dragActive ? "dragActive" : ""}`}>
            Drag & Drop files here
          </p>
        </span>
        <span
          className={`subheading-container ${dragActive ? "dragActive" : ""}`}
        >
          <p className={`subheading ${dragActive ? "dragActive" : ""} `}>
            or click "Upload" button
          </p>
        </span>
      </div>
    </div>
  );
};

export default EmptyFolderImage;
