/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
// import { COMPANY_LOGO } from "../APP_LOGO/app_logo";
// import COMPANY_LOGO from "../../assets/EZ-BYTE-BLUE.png";
// import COMPANY_LOGO from "../../assets/e10.png";
import COMPANY_LOGO from "../../assets/ez-byte/a3.png";
import "../LandingPage/LandingScreen.css";

import myImage from "../../assets/cloud.mp4";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";

export default function LandingScreen() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAnnual, setIsAnnual] = useState(false);
  const [isTopAreaVisible, setIsTopAreaVisible] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToPricing = () => {
    const pricingSection = document.getElementById("pricing-section");
    pricingSection.scrollIntoView({ behavior: "smooth" });
  };

  const togglePricing = () => {
    setIsAnnual((prev) => !prev);
  };

  const navigationHandeler = () => {
    navigate("/login");
  };

  const pricingData = [
    {
      plan: "PERSONAL",
      monthly: "$2.99",
      annual: "$24.99",
      features: [
        "Try free for one month",
        "For 1 user",
        "200 GB Cloud storage",
      ],
      isBusiness: false,
    },
    {
      plan: "FAMILY",
      monthly: "$4.99",
      annual: "$49.99",
      features: [
        "Try free for one month",
        "For upto 6 users",
        "2000 GB Cloud storage",
      ],
      isBusiness: false,
    },
    {
      plan: "BUSINESS",
      monthly: "$2.99",
      annual: "$24.99",
      features: [
        "20 GB Storage per user",
        "20 GB Email share",
        "Branding with business logo",
        "Business email templates",
      ],
      isBusiness: true,
    },
  ];

  const pricingDataForBusiness = [
    {
      plan: "PERSONAL",
      monthly: "$2.99",
      annual: "$24.99",
      features: [
        "Try free for one month",
        "For 1 user",
        "200 GB Cloud storage",
      ],
      isBusiness: false,
    },
    {
      plan: "FAMILY",
      monthly: "$4.99",
      annual: "$49.99",
      features: [
        "Try free for one month",
        "For upto 6 users",
        "2000 GB Cloud storage",
      ],
      isBusiness: false,
    },
    {
      plan: "BUSINESS",
      monthly: "$2.99",
      annual: "$24.99",
      features: [
        "20 GB Storage per user",
        "20 GB Email share",
        "Branding with business logo",
        "Business email templates",
      ],
      isBusiness: true,
    },
  ];

  const handleLogoClick = () => {
    // navigate("/");
  };

  // BUSINESS    /user/month   /user/year

  return (
    <div className="landing-container">
      {isTopAreaVisible && (
        <div className="show-ad">
          Grab the Christmas deal at Unbeatable Price.
          {/* <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              scrollToPricing();
            }}
          >
            Learn more
          </a> */}
        </div>
      )}
      <header
        style={{ top: isTopAreaVisible ? "58px" : "0" }}
        className={`landing-header ${isScrolled ? "scrolled" : ""}`}
      >
        <img
          src={COMPANY_LOGO}
          alt="Logo"
          className="app-logo"
          onClick={handleLogoClick}
        />

        <nav className="landing-nav-bar">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              scrollToPricing();
            }}
          >
            Pricing
          </a>
          {/* <a href="/login">Login</a> */}

          <button className="login" onClick={navigationHandeler}>
            Login
          </button>
        </nav>
      </header>

      <div className="scrollable-content">
        <div className="main-content">
          {/* <img src={myImage} alt="jjj" /> */}
          <video
            src={myImage}
            autoPlay
            loop
            muted
            className="background-video"
          />
        </div>

        <div id="pricing-section" className="container">
          <h2>Plans For Home</h2>

          <div className="toggle-container">
            <span className={!isAnnual ? "active" : ""}>Monthly</span>
            <div className="toggle-switch" onClick={togglePricing}>
              <div
                className={`toggle-knob ${isAnnual ? "annual" : "monthly"}`}
              />
            </div>
            <span className={isAnnual ? "active" : ""}>Annually</span>
          </div>

          <div className="price-row">
            {pricingData.map((item, index) => (
              <div key={index} className="price-col">
                <p className="plan-title">{item.plan}</p>
                <h3>
                  {isAnnual ? item.annual : item.monthly}
                  {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

                  <span>
                    {item.isBusiness
                      ? `/ user ${isAnnual ? "/ year" : "/ month"}`
                      : isAnnual
                      ? "/ year"
                      : "/ month"}
                  </span>
                </h3>
                <ul>
                  {item.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                  {item.features.length < 4 && <li>&nbsp;</li>}
                </ul>
                <button>Subscribe</button>
              </div>
            ))}
          </div>
        </div>

        <div id="pricing-section" className="container">
          <h2>Plans For Business</h2>

          <div className="toggle-container">
            <span className={!isAnnual ? "active" : ""}>Monthly</span>
            <div className="toggle-switch" onClick={togglePricing}>
              <div
                className={`toggle-knob ${isAnnual ? "annual" : "monthly"}`}
              />
            </div>
            <span className={isAnnual ? "active" : ""}>Annually</span>
          </div>

          <div className="price-row">
            {pricingDataForBusiness.map((item, index) => (
              <div key={index} className="price-col">
                <p className="plan-title">{item.plan}</p>
                <h3>
                  {isAnnual ? item.annual : item.monthly}
                  {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

                  <span>
                    {item.isBusiness
                      ? `/ user ${isAnnual ? "/ year" : "/ month"}`
                      : isAnnual
                      ? "/ year"
                      : "/ month"}
                  </span>
                </h3>
                <ul>
                  {item.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                  {item.features.length < 4 && <li>&nbsp;</li>}
                </ul>
                <button>Subscribe</button>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="empty-container"></div> */}
        <footer className="footer">
          <div className="line"></div>
          <div className="footer-container">
            <div className="footer-row">
              <div className="footer-col two">
                <h4>Solutions</h4>
                <ul>
                  <li>
                    <a href="https://citrixbox.com/solutions/#vendor">
                      Vendor Integration
                    </a>
                  </li>
                  <li>{/* <a href="#">Locations</a> */}</li>
                  <li>
                    <a href="https://citrixbox.com/solutions/#erp">
                      ERP Integration
                    </a>
                  </li>
                </ul>
              </div>

              <div className="footer-col one " style={{ marginTop: "-2px" }}>
                <h4>Products</h4>
                <ul>
                  <li>
                    <a href="https://citrixbox.com/products/#fraudguard">
                      Fraud Guard
                    </a>
                  </li>
                  <li>
                    <a href="https://citrixbox.com/products/#ez-store">
                      EZ-Store
                    </a>
                  </li>
                  <li>
                    <a href="https://citrixbox.com/products/#ez-share">
                      EZ-Share
                    </a>
                  </li>
                  <li>
                    <a href="https://citrixbox.com/products/#ez-eye">EZ-Eye</a>
                  </li>
                  <li>
                    <a href="https://citrixbox.com/products/#ez-schedule">
                      EZ-Schedule
                    </a>
                  </li>
                  <li>
                    <a href="https://citrixbox.com/products/#ez-flow">
                      EZ-Flow
                    </a>
                  </li>
                  <li>
                    <a href="https://citrixbox.com/products/#ez-dash">
                      EZ-Dash
                    </a>
                  </li>
                </ul>
              </div>

              <div className="footer-col one">
                <h4>Get Help</h4>
                <ul>
                  <li>
                    <a href="#">Support</a>
                  </li>
                  <li>
                    <a href="#">Return Policy</a>
                  </li>
                  <li>
                    <a href="#">Privacy</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                </ul>
              </div>

              <div className="footer-col">
                <h4>Company</h4>
                <ul>
                  <li>
                    <a href="https://citrixbox.com/aboutus/">About Us</a>
                  </li>
                  <li>
                    <a href="https://citrixbox.com/#contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <p>&copy; 2024 EZ-BYTE. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
}

// export default function LandingScreen() {
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isAnnual, setIsAnnual] = useState(false);
//   const [isTopAreaVisible, setIsTopAreaVisible] = useState(true);

//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 0);
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const scrollToPricing = () => {
//     const pricingSection = document.getElementById("pricing-section");
//     pricingSection.scrollIntoView({ behavior: "smooth" });
//   };

//   const togglePricing = () => {
//     setIsAnnual((prev) => !prev);
//   };

//   const navigationHandeler = () => {
//     navigate("/login");
//   };

//   const pricingData = [
//     {
//       plan: "PERSONAL",
//       monthly: "$2.99",
//       annual: "$25.99",
//       features: [
//         "Try free for one Month",
//         "For 1 Person",
//         "200 GB Cloud Storage",
//       ],
//       isBusiness: false,
//     },
//     {
//       plan: "FAMILY",
//       monthly: "$4.99",
//       annual: "$49.99",
//       features: [
//         "Try free for one Month",
//         "For Upto 10 People",
//         "2000 GB Cloud Storage",
//       ],
//       isBusiness: false,
//     },
//     {
//       plan: "BUSINESS",
//       monthly: "$2.99",
//       annual: "$24.99",
//       features: [
//         "20 GB Storage per Employee",
//         "20 GB Email Share",
//         "Branding with Business Logo",
//         "Business email Templates",
//       ],
//       isBusiness: true,
//     },
//   ];

//   const handleLogoClick = () => {
//     // navigate("/");
//   };

//   // BUSINESS    /user/month   /user/year

//   return (
//     <div className="landing-container">
//       {isTopAreaVisible && (
//         <div className="show-ad">
//           Grab the Christmas deal at FairPrice.{" "}
//           <a
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               scrollToPricing();
//             }}
//           >
//             Learn more
//           </a>
//         </div>
//       )}
//       <header
//         style={{ top: isTopAreaVisible ? "58px" : "0" }}
//         className={`landing-header ${isScrolled ? "scrolled" : ""}`}
//       >
//         <img
//           src={COMPANY_LOGO}
//           alt="Logo"
//           className="app-logo"
//           onClick={handleLogoClick}
//         />

//         <nav className="landing-nav-bar">
//           <a
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               scrollToPricing();
//             }}
//           >
//             Pricing
//           </a>
//           {/* <a href="/login">Login</a> */}

//           <button className="login" onClick={navigationHandeler}>
//             Login
//           </button>
//         </nav>
//       </header>

//       <div className="scrollable-content">
//         <div className="main-content">
//           {/* <img src={myImage} alt="jjj" /> */}
//           <video
//             src={myImage}
//             autoPlay
//             loop
//             muted
//             className="background-video"
//           />
//         </div>

//         <div id="pricing-section" className="container">
//           <h2>Our Pricing Plans</h2>

//           <div className="toggle-container">
//             <span className={!isAnnual ? "active" : ""}>Monthly</span>
//             <div className="toggle-switch" onClick={togglePricing}>
//               <div
//                 className={`toggle-knob ${isAnnual ? "annual" : "monthly"}`}
//               />
//             </div>
//             <span className={isAnnual ? "active" : ""}>Annually</span>
//           </div>

//           <div className="price-row">
//             {pricingData.map((item, index) => (
//               <div key={index} className="price-col">
//                 <p className="plan-title">{item.plan}</p>
//                 <h3>
//                   {isAnnual ? item.annual : item.monthly}
//                   {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

//                   <span>
//                     {item.isBusiness
//                       ? `/ user ${isAnnual ? "/ year" : "/ month"}`
//                       : isAnnual
//                       ? "/ year"
//                       : "/ month"}
//                   </span>
//                 </h3>
//                 <ul>
//                   {item.features.map((feature, i) => (
//                     <li key={i}>{feature}</li>
//                   ))}
//                   {item.features.length < 4 && <li>&nbsp;</li>}
//                 </ul>
//                 <button>Subscribe</button>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* <div className="empty-container"></div> */}
//         <footer className="footer">
//           <div className="line"></div>
//           <div className="footer-container">
//             <div className="footer-row">
//               <div className="footer-col one " style={{ marginTop: "-2px" }}>
//                 <h4>Company</h4>
//                 <ul>
//                   <li>
//                     <a href="#">About Us</a>
//                   </li>
//                   <li>
//                     <a href="#">Our Story</a>
//                   </li>
//                   <li>
//                     <a href="#">Careers</a>
//                   </li>
//                   <li>
//                     <a href="#">Contact</a>
//                   </li>
//                 </ul>
//               </div>

//               <div className="footer-col two">
//                 <h4>Get Help</h4>
//                 <ul>
//                   <li>
//                     <a href="#">Resource</a>
//                   </li>
//                   <li>
//                     <a href="#">Support</a>
//                   </li>
//                   <li>
//                     <a href="#">Return Policy</a>
//                   </li>
//                   <li>
//                     <a href="#">Terms & Conditions</a>
//                   </li>
//                 </ul>
//               </div>

//               <div className="footer-col">
//                 <h4>About</h4>
//                 <ul>
//                   <li>
//                     <a href="#">Team</a>
//                   </li>
//                   <li>
//                     <a href="#">Locations</a>
//                   </li>
//                   <li>
//                     <a href="#">Privacy</a>
//                   </li>
//                   <li>
//                     <a href="#">Terms</a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div className="footer-bottom">
//             <p>&copy; 2024 EZ-BYTE. All rights reserved.</p>
//           </div>
//         </footer>
//       </div>
//     </div>
//   );
// }
