import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "../components/SearchInput.css";
import ClearIcon from "@mui/icons-material/Clear";

export default function SearchInput({ files, handleFileSearch }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [matchingSuggestions, setMatchingSuggestions] = useState([]);
    const [searchClose, setSearchClose] = useState(false);

    const handleSearchInputKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            fileSearch();
        }
    };

    const handleSearchClose = () => {
        setSearchTerm("");
        setSearchClose(false);
        setMatchingSuggestions([]);
    };

    const handleSearchIcon = () => {
        fileSearch();
    };

    const fileSearch = (_searchTerm) => {
        const trimmedSearchTerm = _searchTerm !== undefined ? _searchTerm.trim() : searchTerm.trim();
        
        if (trimmedSearchTerm.length > 0) {
            const filteredItems = files.filter((file) => {
                const filePath = file.name.toLowerCase(); // Ensure only filename is considered for search 
                const fileName = filePath.split('/').pop();
                return (fileName.includes(trimmedSearchTerm.toLowerCase()) &&
                    fileName !== "emptyfile.emptyfile" &&
                    fileName.length > 0
                );
            });

            const newCurrentItems = filteredItems.map((item) => ({
                ...item,
                path: item.name,
                name: item.name.split("/").pop(),
                location: item.name.split("/").slice(0, -1).pop(),
                isFolder: item.folder,
            }));
            handleFileSearch(newCurrentItems);
        }
        setMatchingSuggestions([]);
        setSearchClose(true);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value.trim());
        e.target.value === "" ? setSearchClose(false) : setSearchClose(true);

        if (value.length > 0) {
            const filteredSuggestions = suggestions.filter((suggestion) =>
                suggestion.toLowerCase().includes(value.toLowerCase())
            );
            setMatchingSuggestions(filteredSuggestions);
        } else {
            setMatchingSuggestions([]);
        }
    };

    useEffect(() => {
        const fileSuggestions = files.map((file) => {
            const filePath = file.name.trim(); // Ensure only filename is considered for search 
            const fileName = filePath.split('/').pop();
            return fileName;
        })
        .filter(fileName => fileName !== "emptyfile.emptyfile");;
        setSuggestions(fileSuggestions);
    }, [files]);
   
    const handleSuggestionClick = (suggestion) => {
        setSearchTerm(suggestion.trim());
        setMatchingSuggestions([]);
        setSearchClose(true);
        fileSearch(suggestion.trim());
    };

    return (
        <div className="header1-search">
            <SearchIcon onClick={handleSearchIcon} />
            <input type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleChange}
                onKeyDown={handleSearchInputKeyDown}
            />
            {searchClose &&
                <ClearIcon fontSize="small" onClick={handleSearchClose} />
            }
            {matchingSuggestions.length > 0 && (
                <ul className="suggestions-list">
                    {matchingSuggestions.map((suggestion, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};