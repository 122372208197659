import { useState } from "react";

import "../screens/LoginScreen.css";

import applogo1 from "../assets/logo5.jpg";

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import SignInWithGoogle from "../components/SignInWithGoogle";
import { COMPANY_LOGO } from "../components/APP_LOGO/app_logo";

export default function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const navigate = useNavigate();

  function emailHandler(e) {
    setEmail(e.target.value);
  }

  function passwordHandler(e) {
    setPassword(e.target.value);
  }

  async function handelSubmit(e) {
    e.preventDefault();

    setMessage("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setMessage("Login successful!");
      setMessageType("success");
      navigate("/home");
    } catch (error) {
      setMessage("Incorrect email or password");
      setMessageType("error");
    }
  }

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="logo-container">
          <img src={COMPANY_LOGO} alt="appLogo" className="login-logo" />
        </div>
        <form className="login-form" onSubmit={handelSubmit}>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={emailHandler}
            className="login-input"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={passwordHandler}
            className="login-input"
            required
          />
          <button type="submit" className="login-button">
            Submit
          </button>
        </form>
        {message && <p className={`message ${messageType}`}>{message}</p>}
        {/* <p className="para-login">
          New user? <a href="/register">Register Here</a>
        </p> */}
        <SignInWithGoogle />
      </div>
    </div>
  );
}
