// SortableHeader.js
import React, { useState } from "react";

const SortableHeader = ({
  title,
  onSort,
  hoverText,
  marginLeft,
  icon,
  hoverSpan,
  dragActive,
  headingWidth,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <p
      style={{
        width: headingWidth,
        position: "relative",
        cursor: "pointer",
        backgroundColor: `${dragActive ? `var(--light-blue-color)` : ``}`,
      }} // Add cursor and positioning
    >
      <b
        style={{
          marginLeft: marginLeft,
          fontSize: "13px",
          backgroundColor: `${dragActive ? `var(--light-blue-color)` : ``}`,
        }}
        onClick={onSort} // Sort when clicked
        onMouseEnter={() => setIsHovered(true)} // Show sort text on hover
        onMouseLeave={() => setIsHovered(false)} // Hide sort text when not hovering
      >
        {icon} {title}
      </b>
      {isHovered && (
        <span
          style={{
            fontSize: "0.8em",
            color: "white",
            position: "absolute",
            left: hoverSpan,
            top: "100%",
            whiteSpace: "nowrap",
            padding: "5px 7px",
            borderRadius: "5px",
            backgroundColor: "#333",
          }}
        >
          {hoverText}
        </span>
      )}
    </p>
  );
};

export default SortableHeader;
